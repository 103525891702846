import React, {useEffect, useState} from "react";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {useHistory} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../service/host";
import OrderContract from "./order.contract";
import OrderAssignment from "./order.assignment";
import OrderFacturation from "./order.facturation";
import Details from "../../assignment/details/details";
import AssignmentCollab from "../../assignment/components/assignment.order/assignment.collab";
import AssignmentContractTypeClient from "../../assignment/components/assignment.client/assignment.contractTypeClient";
import AssignementClient from "../../assignment/components/assignment.client/assignement.client";
import CustomInput from "reactstrap/es/CustomInput";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const context = "orders";
export default function OrderForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        control,
        unregister
    } = useForm();

    const [edit, setEdit] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [order, setOrder] = useState({});
    const [selectedObjects, setSelectedObjects] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const [assignment, setAssignment] = useState({});
    const [savedAssignment, setSavedAssignment] = useState({});
    const [showUndirect, setShowUndirect] = useState(false);
    const [directClient, setDirectClient] = useState({});
    const [subClient, setSubClient] = useState({});
    const [type, setType] = useState(true);
    const [linesRate, setLinesRate] = useState([]);
    const [linesDiscount, setLinesDiscount] = useState([]);
    const [errorMessageClient, setErrorMessageClient] = useState("")
    const [errorMessageSubClient, setErrorMessageSubClient] = useState("")
    const [modalLoadingShow, setModalLoadingShow] = useState(false);


    let history = useHistory();

    const cancelCreateOrder = data => {
        history.push("/orders");
    };

    const orderId = initAssignmentId();

    function initAssignmentId() {
        const {
            match: {params}
        } = props;
        return params.orderId;
    }

    useEffect(
        () => {
            if (orderId !== undefined) {
                setEdit(true)
                service.getById(
                    getHostByAppName(ASSIGNMENT_NAME),
                    context,
                    callBackOrder,
                    setErrorMessage,
                    orderId
                );
            }
        },
        [orderId]
    );

    useEffect(
        () => {
            if (savedAssignment && savedAssignment.id) {
                service.get(
                    getHostByAppName(ASSIGNMENT_NAME),
                    `rates/findBy/assignment/${savedAssignment.id}`,
                    setLinesRate,
                    (error)=>console.log(error)
                );
                service.get(
                    getHostByAppName(ASSIGNMENT_NAME),
                    `discounts/findBy/assignment/${savedAssignment.id}`,
                    setLinesDiscount,
                    (error)=>console.log(error)
                );
            }
        },
        [savedAssignment]
    );

    const callBackOrder = data =>{
        setOrder(data)
        setSelectedObjects({assignment: data.assignment});
    }

    const onSubmitOrder = data => {
        console.log("Save assignment isValid: ", isValid);
        if (isValid) {
            setModalLoadingShow(true)
            if(!type){
                if(!directClient.id || !(directClient.contacts && directClient.contacts.length>0) || !(directClient.contactsOp && directClient.contactsOp.length>0)){
                    setErrorMessageClient("champs obligatoires : Client, Contact facturation et opérationnel")
                    setModalLoadingShow(false)
                    return;
                }

                if(data.subContracting === "true" && !subClient.id){
                    setErrorMessageSubClient("champs obligatoires : Client")
                    setModalLoadingShow(false)
                    return;
                }
            }
            let mappedData = mapData(data);
            if(mappedData.employee && mappedData.directClient && mappedData.directClient.client && mappedData.beginAt) {
                console.log("Save assignment : ", mappedData);
                console.log(JSON.stringify(mappedData));
                service.post(getHostByAppName(ASSIGNMENT_NAME), `${context}`, callbackRes, callbackError, mappedData);
                setIsValid(false)
                setModalLoadingShow(false)
            }else{
                setErrorMessage("Champs obligatoires : Collaborateur, Date début et Client")
                setModalLoadingShow(false)
            }
            setModalLoadingShow(false)
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = (res) => {
        console.log(res.data);
        setEdit(false);
        history.push(`/${context}/${res.data.id}/edit`);
    }

    const callbackError = (error) => {
        console.log("error to print", error.message);
        setErrorMessage(error.response.data.message)
    }

    const mapData = data => {
        console.log("order data", data)
        console.log("new ", type)
        console.log("savedAssignment ", savedAssignment)
        console.log("savedAssignment directClient", directClient)
        console.log("savedAssignment subClient", subClient)
        let dataTransforme = {}
        if(type && savedAssignment && savedAssignment.id){
            dataTransforme = {
                billingAddOns: data.billingAddOns != undefined && data.billingAddOns.length > 0 ? data.billingAddOns : [],
                organization: savedAssignment.organization,
                employee: savedAssignment.employee,
                responsible: savedAssignment.responsible,
                termsOfPayment:savedAssignment.termsOfPayment,
                directClient: savedAssignment.directClient,
                subContractingClient: savedAssignment.subContractingClient ? savedAssignment.subContractingClient : null,
                beginAt: savedAssignment.beginAt !== undefined ? savedAssignment.beginAt : null,
                endAt: savedAssignment.endAt !== undefined ? savedAssignment.endAt : null,
                missionType: savedAssignment.missionType,
                quantity: savedAssignment.quantity,
                rates: savedAssignment.id !== undefined ? linesRate : [],
                discounts: savedAssignment.id != undefined ? linesDiscount : [],
                designation: savedAssignment.designation,
                subContracting: savedAssignment.subContracting,
                assignment: savedAssignment.id,
                task: savedAssignment.task
            };
        }else{
            dataTransforme = {
                billingAddOns: data.billingAddOns != undefined && data.billingAddOns.length > 0 ? data.billingAddOns : [],
                organization: GetOrganizationIdFromSession(),
                employee: data.employee,
                responsible: data.responsible,
                termsOfPayment:directClient.termsOfPayment,
                directClient: {
                    client: directClient.id,
                    clientName : directClient.name,
                    group: directClient.group,
                    entity: directClient.entity,
                    entityName:directClient.entityName,
                    contacts: directClient.contacts !== undefined ? directClient.contacts : [],
                    complementaryAddress: directClient.complementaryAddress,
                    address: data.client.addressObj
                },
                subContractingClient: subClient.id !== undefined ? {
                    client: subClient.id,
                    clientName : subClient.name,
                    group: subClient.group,
                    entity: subClient.entity ? subClient.entity:"",
                    entityName:subClient.entityName ? subClient.entityName:"",
                    contacts: subClient.contacts !== undefined ? subClient.contacts : [],
                    complementaryAddress: subClient.complementaryAddress,
                    address: data.subContractingClient.addressObj
                } : null,
                reference : data.reference,
                beginAt: data.beginAt !== undefined ? data.beginAt.replace("T", " ") : null,
                endAt: data.endAt !== undefined ? data.endAt.replace("T", " ") : null,
                missionType: data.missionType,
                quantity: data.quantity,
                rates: data.rates !== undefined ? data.rates : [],
                discounts: data.discounts != undefined ? data.discounts : [],
                designation: data.designation,
                subContracting: data.subContracting,
                task:data.task
            };
        }

        return dataTransforme;
    };

    const dispatch = (obj) => {
        console.log("selectItems values : ", selectedObjects)
        setSelectedObjects({...selectedObjects, ...obj});
    }


    return (
        <div>
            <Row>
                <Col className="col-sm-3">
                    <div>
                        <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}><IntlMessages id="order.create"/></h4>

                    </div>
                </Col>
            </Row>
            <br/>
            <form onSubmit={handleSubmit(onSubmitOrder)}>
                <Row>
                    <Col className="col-sm-6">
                        <div className="display-5 c-text-danger">{errorMessage}</div>
                    </Col>
                </Row>

                <div className="row ma-0 ptb-10">
                    <div className="col-sm-12 ">

                        <div className="roe-card-style mt-3">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="creation.type"/>
                            </div>
                            <div className="pl-5">
                            <Row>
                                <Col className="mt-2">
                                    <FormGroup>
                                        <CustomInput
                                            id="idTCNew"
                                            type="radio"
                                            name="type"
                                            value={false}
                                            onClick={() => setType(false)}
                                            label={
                                                <IntlMessages id="creation.type.new"/>
                                            }
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="mt-2">
                                    <FormGroup>
                                        <CustomInput
                                            id="id4FOR"
                                            type="radio"
                                            name="type"
                                            value={true}
                                            defaultChecked={true}
                                            onClick={() => setType(true)}
                                            label={
                                                <IntlMessages id="creation.type.from.order"/>
                                            }
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            </div>
                        </div>

                    </div>
                </div>

                {type && <div className="row ma-0 ptb-10">
                    <div className="col-sm-12 ">

                        <div className="roe-card-style mt-3">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="assignment"/>
                            </div>

                            <OrderAssignment control={control} dispatch={dispatch} setAssignment={setSavedAssignment} edit={true} order={order} setValue={setValue} errors={errors} register={register}/>
                            {savedAssignment && savedAssignment.id && <Details hideDetails={false} selectedElement={{id: savedAssignment && savedAssignment.id ? savedAssignment.id : undefined}} context={`assignments`}/>}

                        </div>

                    </div>
                </div>}

                {!type && <Row className="ptb-15">
                    <Col className="col-sm-12">
                        <AssignmentCollab
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            assignment={assignment}
                            edit={edit}
                        />
                    </Col>
                </Row>}

                {!type && <div className="row ma-0 pb-3">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="assignment.client.type"/>
                            </div>
                            <div className="roe-card-body col-sm-12">
                                <Row className="col-sm-3">
                                    <Col>
                                        <AssignmentContractTypeClient
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            assignment={assignment}
                                            setShowUndirect={setShowUndirect}
                                            showUndirect={showUndirect}
                                            edit={edit}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>}

                {!type && <div className="row ma-0">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style">
                            <div className="roe-card-header">
                                <span className="hash"># </span>
                                {!showUndirect && (<IntlMessages id="assignment.client.direct"/>)}
                                {showUndirect && (<IntlMessages id="assignment.client.undirect"/>)}
                            </div>
                            <div className="roe-card-body col-sm-12">
                                <Row>
                                    <Col className="col-sm-12">
                                        <AssignementClient dispatch={dispatch} selectedObjects={selectedObjects}
                                                           errors={errors}
                                                           register={register}
                                                           setValue={setValue}
                                                           reset={reset}
                                                           client={directClient}
                                                           setClient={setDirectClient}
                                                           edit={edit}
                                                           control={control}
                                                           clientType={"client"}
                                                           Controller={Controller} errorMessageClient={errorMessageClient}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>}

                {showUndirect && !type && (
                    <div className="row ma-0 pt-3">
                        <div className="col-sm-12 ">
                            <div className="roe-card-style">
                                <div className="roe-card-header">
                                    <span className="hash"># </span><IntlMessages id="assignment.client.direct"/>
                                </div>
                                <div className="roe-card-body col-sm-12">
                                    <Row>
                                        <Col className="col-sm-12">
                                            <AssignementClient
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                control={control}
                                                client={subClient}
                                                setClient={setSubClient}
                                                edit={edit}
                                                clientType={"subContractingClient"}
                                                Controller={Controller} errorMessageClient={setErrorMessageSubClient}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row ma-0 ptb-15">
                    <div className="col-sm-12 ">
                        <div className="roe-card-style mt-3">
                            <div className="roe-card-header">
                                <span className="hash"># </span><IntlMessages id="info.comp.fact"/>
                            </div>

                            <OrderFacturation control={control} dispatch={dispatch} edit={true} order={order} setValue={setValue} errors={errors} register={register}/>

                        </div>

                    </div>
                </div>


                <br/>
                <Row>
                    <Col className="col-sm-9"/>
                    <Col>

                            <FormGroup>
                                <AdaButton
                                    className="c-btn c-danger w-100"
                                    onClick={cancelCreateOrder}
                                >
                                    <IntlMessages id="action.common.cancel"/>
                                </AdaButton>
                            </FormGroup>

                    </Col>
                    <Col>

                            <FormGroup>
                                <AdaButton
                                    className="c-btn c-warning w-100"
                                    type="submit"
                                    onClick={() => setIsValid(true)}
                                    style={{marginLeft: 10}}
                                >
                                    <IntlMessages id="action.common.submit"/>
                                </AdaButton>
                            </FormGroup>

                    </Col>

                </Row>
                <Modal
                    show={modalLoadingShow}
                    size="sm"
                    centered
                    backdrop={"static"}
                >
                    <div className="align-self-center ">
                        <Row className="pt-3">
                            <Col>
                                <Spinner animation="border" color="warning" role="status"
                                         style={{width: '5rem', height: '5rem'}}/>
                            </Col>
                        </Row>
                        <Row className="pt-3 pb-2"><Col>
                            <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                Loading ...
                            </div>
                        </Col></Row>
                    </div>
                </Modal>
            </form>
        </div>
    );
}
